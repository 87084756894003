import React from 'react'
import Img from 'gatsby-image'

const Therapist = props => (
  <div key={props.id} className="therapist-card">
    <Img
      fluid={props.image.childImageSharp.fluid}
      alt={props.name}
      className="therapist-img"
      objectFit="cover"
      objectPosition="50% 50%"
    />
    <h5>{props.name}</h5>
    {
      props.link === "#" ?
      <p>{props.title}</p>
      :
      <a href={props.link} target="_blank" rel="noopener noreferrer"><p>{props.title}</p></a>
    }
  </div>
)

export default Therapist;
