import React from "react"
import { Link, graphql } from 'gatsby'
import Layout from '../components/Layout'
import HeroImage from '../components/HeroImage'
import Therapist from '../components/Therapist'
import SEO from '../components/SEO'

const OurPractice = ({ data }) => {
  return(
    <Layout>
    <SEO
      title="Our Practice"
    />
    <HeroImage heroText="Our Practice" cta="" class="hero-image" source="/static/mbody_sign-7ef6579a1b367143ce7a4c0f0b471b2e.jpg" />
    <div className='padding-content-section alert-dismissed'>
      <h4 className="mission-statement">
        "Our Mission is to promote holistic, healthy body works through professional massage therapy and
        committed excellence. It is our desire to offer clients quality service with integrity, professionalism, and optimal care."
      </h4>
      <p>
        mBody Health is located at 908 E Waterloo Rd Suite 3 in Akron, Ohio.
        We offer an incredible and affordable massage therapy program, offering full body, half body and body section massages.
        Each is tailored to your personal need in relieving tension and stress, while improving circulation and range of motion.
      </p>
      <p>
        We are open Tuesday through Thursday, 9:00 AM to 5:00 PM and Saturday 9:00 AM to 3:00 PM.
        <br/><strong>Clients are served by appointment only.</strong>
      </p>
      <p>
        Our office accepts physician referrals with prescriptions for medical massages and is CAQH credentialed and BWC certified.
        Please inquire as some services may not be covered by your insurance.
      </p>
      <p>
        mBody Health's style of service allows you the autonomy to be active in your body's health  and maintenance.
        As you work with our trained professional staff, understand that we will care for you with honesty;
        only offering advice and services necessary to promote your body's health.
      </p>
    </div>
    <div className="tertiary padding-content-section">
      <h3 className="responsive-title">Our Team</h3>
      <div>
      {data.allMarkdownRemark.edges.map(({ node }) => (
          <Therapist
            key={node.id}
            title={node.frontmatter.title}
            name={node.frontmatter.name}
            link={node.frontmatter.link}
            image={node.frontmatter.image}
          />
        ))
      }
      </div>
    </div>
    <div className="padding-content-section">
      <h4 className="responsive-title">Ready to take the next step?</h4>
      <Link to="/contact" aria-label="Request an Appointment"><button className="button button-primary">Request an Appointment</button></Link>
    </div>
  </Layout>
  )
}

export default OurPractice;

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___order], order: ASC }) {
      edges {
        node {
          id
          frontmatter {
            name
            title
            link
            image {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
